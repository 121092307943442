// todoSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { kyc_updatebyself,Check_IFSC,Add_New_Beneficiary,
  Check_Account_Verify,List_Beneficiary,
  Profile_agent_,kyc_AadharFrontbyself,
  kyc_AadharBackbyself,kyc_PANbyself,Check_UPIVerify,Added_UPIVerify,
  List_UPI,Choosebeneficiary,Transfer_AMOUNT,OTP_AMOUNT,Check_Balance,
  Transfer_History,Recharge_Payment,Check_BalanceDebit,
  List_WithoutPage_Agent,OnBoard_PaysPrint,OnBoard_PaysPrintRegister,
  AEPS_BankList,AEPS_Balance_Enquery,PaysPrintAuth,agent_merchant_authencity,Agent_List_New,AEPS_Report,
  Agent_Logout,CreditToDebit,CheckActiveService,AgentLagerReport,AgentLagerCreditReport,
  CheckAEPS_OnboarURL,
  DashboardActiveService,AgentMPINOTP,
  AgentSavempin,DMTQueryRemmiter,
  RegisterRemmtter,
  AgentUPILoad,
  UPIBalanceStatus,
  UPILoadReport,
  RechargeOffer,
  checkOperator,checkreOffer,
  Profile_agentpost,
  AnshExpressSendOTP,
  AnshExpressRegisterUser,
  AnshExpressBenificery,
  AnshExpressMoenyTransfer,ExpressUsers,
  ExpressUserBeneificery,
  ExpressTransations,
  Agentdashboardreports,
  AgentPhoto,
  Transfer_PayOutHistory,
  AnshExpressRegisterUserFetch,
  AgentDebitReportsWithUplines,
  AnshExpressMoenyTransferConfirm
} from './Api2';

export const kyc_updated = createAsyncThunk('user/kyc_updated', async (filter) => {
  const response = await kyc_updatebyself(filter);
  return response.data;
});


export const kyc_AadharFront = createAsyncThunk('user/kyc_AadharFront', async (filter) => {
  const response = await kyc_AadharFrontbyself(filter);
  return response.data;
});
export const AgentProfileImage = createAsyncThunk('user/AgentProfileImage', async (filter) => {
  const response = await AgentPhoto(filter);
  return response.data;
});
export const kyc_AadharBack = createAsyncThunk('user/kyc_AadharBack', async (filter) => {
  const response = await kyc_AadharBackbyself(filter);
  return response.data;
});
export const kyc_PANSelf = createAsyncThunk('user/kyc_PANSelf', async (filter) => {
  const response = await kyc_PANbyself(filter);
  return response.data;
});




export const Check_IFSC_Code = createAsyncThunk('user/Check_IFSC_Code', async (filter) => {
  const response = await Check_IFSC(filter);
  return response.data;
});

export const Check_UPI_User = createAsyncThunk('user/Check_UPI_User', async (filter) => {
  const response = await Check_UPIVerify(filter);
  return response.data;
});
export const Added_UPI_User = createAsyncThunk('user/Added_UPI_User', async (filter) => {
  const response = await Added_UPIVerify(filter);
  return response.data;
});

export const OTP_Payment = createAsyncThunk('user/OTP_Payment', async (filter) => {
  const response = await OTP_AMOUNT(filter);
  return response.data;
});

export const Check_Balance_Wallet = createAsyncThunk('user/Check_Balance_Wallet', async (filter) => {
  const response = await Check_Balance(filter);
  return response.data;
});

export const Check_Balance_DebitWallet = createAsyncThunk('user/Check_Balance_DebitWallet', async (filter) => {
  const response = await Check_BalanceDebit(filter);
  return response.data;
});
export const Transfer_History_Wallet = createAsyncThunk('user/Transfer_History_Wallet', async (filter) => {
  const response = await Transfer_History(filter);
  return response.data;
});

export const Transfer_History_Payout = createAsyncThunk('user/Transfer_History_Payout', async (filter) => {
  const response = await Transfer_PayOutHistory(filter);
  return response.data;
});


export const Transfer_History_Express = createAsyncThunk('user/Transfer_History_Express', async (filter) => {
  const response = await ExpressTransations(filter);
  return response.data;
});

export const Transfer_Payment = createAsyncThunk('user/Transfer_Payment', async (filter) => {
  const response = await Transfer_AMOUNT(filter);
  return response.data;
});

export const Recharge = createAsyncThunk('user/Recharge', async (filter) => {
  const response = await Recharge_Payment(filter);
  return response.data;
});

export const RechargeOfferAgent = createAsyncThunk('user/RechargeOfferAgent', async (filter) => {
  const response = await RechargeOffer(filter);
  return response.data;
});

export const CheckOperatorMobile = createAsyncThunk('user/CheckOperatorMobile', async (filter) => {
  const response = await checkOperator(filter);
  return response.data;
});

export const RechargeReOfferAgent = createAsyncThunk('user/RechargeReOfferAgent', async (filter) => {
  const response = await checkreOffer(filter);
  return response.data;
});


export const Account_Verify = createAsyncThunk('user/Account_Verify', async (filter) => {
  const response = await Check_Account_Verify(filter);
  return response.data;
});

export const New_Beneficiary = createAsyncThunk('user/New_Beneficiary', async (filter) => {
  const response = await Add_New_Beneficiary(filter);
  return response.data;
});

export const AllList_Beneficiary = createAsyncThunk('user/AllList_Beneficiary', async (filter) => {
  const response = await List_Beneficiary(filter);
  return response.data;
});

export const Agent_Profile = createAsyncThunk('user/Agent_Profile', async (filter) => {
  const response = await Profile_agent_(filter);
  return response.data;
});

export const Agent_ProfilePosts = createAsyncThunk('user/Agent_ProfilePosts', async (filter) => {
  const response = await Profile_agentpost(filter);
  return response.data;
});

export const AllListUPI = createAsyncThunk('user/AllListUPI', async (filter) => {
  const response = await List_UPI(filter);
  return response.data;
});

export const Choose_Beneficiary = createAsyncThunk('user/Choose_Beneficiary', async (filter) => {
  const response = await Choosebeneficiary(filter);
  return response.data;
});
export const AllList_Agent_WithoutPage = createAsyncThunk('user/AllList_Agent_WithoutPage', async () => {
  const response = await List_WithoutPage_Agent();
  return response.data;
});
export const Agent_OnBoard = createAsyncThunk('user/Agent_OnBoard', async () => {
  const response = await OnBoard_PaysPrint();
  return response.data;
});

export const Agent_Register_OnBoard = createAsyncThunk('user/Agent_Register_OnBoard', async (filter) => {
  const response = await OnBoard_PaysPrintRegister(filter);
  return response.data;
});


export const Agent_PaysPrintAuth = createAsyncThunk('user/Agent_PaysPrintAuth', async (filter) => {
  const response = await PaysPrintAuth(filter);
  return response.data;
});


export const agent_Merchant_auth = createAsyncThunk('user/agent_Merchant_auth', async (filter) => {
  const response = await agent_merchant_authencity(filter);
  return response.data;
});

export const Balance_Enquery = createAsyncThunk('user/Balance_Enquery', async (filter) => {
  const response = await AEPS_Balance_Enquery(filter);
  return response.data;
});

export const banklist = createAsyncThunk('user/banklist', async () => {
  const response = await AEPS_BankList();
  return response.data;
});


export const agent_List_New = createAsyncThunk('user/agent_List_New', async (filter) => {
  const response = await Agent_List_New(filter);
 
  return response.data;
});

export const AEPS_Report_List= createAsyncThunk('user/AEPS_Report_List', async (filter) => {
  const response = await AEPS_Report(filter);
 
  return response.data;
});

export const logout = createAsyncThunk('user/logout', async () => {
  const response = await Agent_Logout();
  return response.data;
});
export const WalletCreditToDebit= createAsyncThunk('user/CreditToDebit', async (filter) => {
  const response = await CreditToDebit(filter);

  return response.data;
});

export const ActiveService= createAsyncThunk('user/ActiveService', async (filter) => {
  const response = await CheckActiveService(filter);

  return response.data;
});

export const AgentReports= createAsyncThunk('user/AgentReports', async (filter) => {
  const response = await AgentLagerReport(filter);
 
  return response.data;
});

export const AgentDebitReports= createAsyncThunk('user/AgentDebitReports', async (filter) => {
  const response = await AgentDebitReportsWithUplines(filter);
 
  return response.data;
});

export const AgentCreditReports= createAsyncThunk('user/AgentCreditReports', async (filter) => {
  const response = await AgentLagerCreditReport(filter);
 
  return response.data;
});

export const AgentUPILoadReports= createAsyncThunk('user/AgentUPILoadReports', async (filter) => {
  const response = await UPILoadReport(filter);
 
  return response.data;
});

export const AgentOnboardURL= createAsyncThunk('user/AgentOnboardURL', async (filter) => {
  const response = await CheckAEPS_OnboarURL(filter);
 // console.log(response)
  return response.data;
});
export const AgentDashboardService= createAsyncThunk('user/AgentDashboardService', async (filter) => {
  const response = await DashboardActiveService(filter);
 // console.log(response)
  return response.data;
});

// export const AgentChnageNewPassword= createAsyncThunk('user/AgentChnageNewPassword', async (filter) => {
//   const response = await NewChangePassword(credentials);
//  // console.log(response)
//   return response.data;
// });

export const MPin_Agent= createAsyncThunk('user/MPin_Agent', async (filter) => {
  const response = await AgentMPINOTP(filter);
 // console.log(response)
  return response.data;
});

export const MPinSave= createAsyncThunk('user/MPinSave', async (filter) => {
  const response = await AgentSavempin(filter);
 // console.log(response)
  return response.data;
});

export const DMTQueryRemmitter = createAsyncThunk('user/DMTQueryRemmitter', async (filter) => {
  const response = await DMTQueryRemmiter(filter);
  return response.data;
});

export const DMTRegisterRemmtter = createAsyncThunk('user/DMTRegisterRemmtter', async (filter) => {
  const response = await RegisterRemmtter(filter);
  return response.data;
});


export const AgentUPILoadDebit = createAsyncThunk('user/AgentUPILoadDebit', async (filter) => {
  const response = await AgentUPILoad(filter);
  return response.data;
});

export const AgentUPILoadDebitSTATUS = createAsyncThunk('user/AgentUPILoadDebitSTATUS', async (filter) => {
  const response = await UPIBalanceStatus(filter);
  return response.data;
});


export const ExpressSendOTP = createAsyncThunk('user/ExpressSendOTP', async (filter) => {
  const response = await AnshExpressSendOTP(filter);
  return response.data;
});

export const ExpressRegister = createAsyncThunk('user/ExpressRegister', async (filter) => {
  const response = await AnshExpressRegisterUser(filter);
  return response.data;
});

export const ExpressRegisterFetch = createAsyncThunk('user/ExpressRegisterFetch', async (filter) => {
  const response = await AnshExpressRegisterUserFetch(filter);
  return response.data;
});


export const ExpressBenificery = createAsyncThunk('user/ExpressBenificery', async (filter) => {
  const response = await AnshExpressBenificery(filter);
  return response.data;
});

export const ExpressMoneyTransfer = createAsyncThunk('user/ExpressMoneyTransfer', async (filter) => {
  const response = await AnshExpressMoenyTransfer(filter);
  return response.data;
});

export const ExpressMoneyTransferConfirm = createAsyncThunk('user/ExpressMoneyTransferConfirm', async (filter) => {
  const response = await AnshExpressMoenyTransferConfirm(filter);
  return response.data;
});

export const ExpressUsersList = createAsyncThunk('user/ExpressUsersList', async (filter) => {
  const response = await ExpressUsers(filter);
  return response.data;
});

export const ExpressUsersBenList = createAsyncThunk('user/ExpressUsersBenList', async (filter) => {
  const response = await ExpressUserBeneificery(filter);
  return response.data;
});

export const Dashboardreports = createAsyncThunk('user/Dashboardreports', async (filter) => {
  const response = await Agentdashboardreports(filter);
  return response.data;
});



const UserSlice = createSlice({
  name: 'user',
  initialState: {
    isLoading: false,
    error: null,
    message: '',
    userKycDetail:null,
    IfscDetails:null,
    UPIDetails:null,
    AccountVerify:null,
    beneficiaryUser:null,
    enqueryDetails :null,
    itemsList:[],
    beneficiaryList:[],
    transationList:[],
    rechargetransationList:[],
    expresstransationList:[],
    aepstransationList:[],
    payouttransationList:[],
    upiList:[],
    agentsList:[],
    bankAEPSList:[],
    activeServiceList:[],
    legerReport:[],
    debitlegerReport:[],
    responseDetails:null,
    aepsDetails:null,
    aepsAuthDetails:null,
    expressMoneyUser:[],
    itemCount: 0,
    perPage: 10,
    page: 1,
    pageCount: 0,
    profileDetails:null,
    profileDetail:null,
    isToken:null,
    wallet:null,
    callbackdata:null,
    activeList:[]
  },


  reducers: {},
  extraReducers: (builder) => {
    builder
    
    .addCase(kyc_updated.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(kyc_updated.fulfilled, (state, action) => {
      
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload;
      localStorage.setItem("agent_isKyc",action.payload.message.updatedData.kyc_status)


    })
    .addCase(kyc_updated.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    })

    .addCase(Choose_Beneficiary.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(Choose_Beneficiary.fulfilled, (state, action) => {
     
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload;
     
    })
    .addCase(Choose_Beneficiary.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    })
    .addCase(kyc_AadharFront.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(kyc_AadharFront.fulfilled, (state, action) => {
       
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload;
    

    })
    .addCase(kyc_AadharFront.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    })
    .addCase(AgentProfileImage.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(AgentProfileImage.fulfilled, (state, action) => {
       
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload;
    

    })
    .addCase(AgentProfileImage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    })
    
    .addCase(kyc_AadharBack.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(kyc_AadharBack.fulfilled, (state, action) => {
       
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload;
    

    })
    .addCase(kyc_AadharBack.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    })
    .addCase(kyc_PANSelf.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(kyc_PANSelf.fulfilled, (state, action) => {
      
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload;
    

    })
    .addCase(kyc_PANSelf.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    })
    
    .addCase(New_Beneficiary.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })

    
    .addCase(New_Beneficiary.fulfilled, (state, action) => {
       
        if(action.payload.success==true){
        
          state.success = action.payload.success;
          state.message = action.payload.message;
          state.beneficiaryUser = action.payload.message.message;
        }else{
        
          state.success = action.payload.success;
          state.message = action.payload.message;
          state.beneficiaryUser = action.payload.message.message;
        }

      state.isLoading = false;
     
    })
    .addCase(New_Beneficiary.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    }) 
    .addCase(Check_IFSC_Code.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(Check_IFSC_Code.fulfilled, (state, action) => {
      
        if(action.payload.success==true){
        
          state.success = action.payload.success;
          state.message = action.payload.message;
          state.IfscDetails = action.payload.message.message;
        }else{
        
          state.success = action.payload.success;
          state.message = action.payload.message;
          state.IfscDetails = action.payload.message.message;
        }

      state.isLoading = false;
      
    })
    .addCase(Check_IFSC_Code.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    }) 
    .addCase(Check_UPI_User.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(Check_UPI_User.fulfilled, (state, action) => {
     
        if(action.payload.success==true){
         
          state.success = action.payload.success;
          state.message = action.payload.message;
        
        }else{
        
          state.success = action.payload.success;
          state.message = action.payload.message;
         
        }

      state.isLoading = false;
      
    })
    .addCase(Check_UPI_User.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    }) 
    .addCase(Added_UPI_User.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(Added_UPI_User.fulfilled, (state, action) => {
     
        if(action.payload.success==true){
         
          state.success = action.payload.success;
          state.message = action.payload.message;
          state.UPIDetails = action.payload.message.message;
        }else{
         
          state.success = action.payload.success;
          state.message = action.payload.message;
          state.UPIDetails = null;
        }

      state.isLoading = false;
      
    })
    .addCase(Added_UPI_User.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    }) 
    .addCase(Account_Verify.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(Account_Verify.fulfilled, (state, action) => {
     
        if(action.payload.success==true){
         
          state.success = action.payload.success;
          state.message = action.payload.message;
          state.AccountVerify = action.payload.message.message;
        }else{
          
          state.success = action.payload.success;
          state.message = action.payload.message;
          state.AccountVerify = action.payload.message.message;
        }

      state.isLoading = false;
      
    })
    .addCase(Account_Verify.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    }) 
    .addCase(AllList_Beneficiary.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(AllList_Beneficiary.fulfilled, (state, action) => {
     
      if(action.payload.success==true){

        state.beneficiaryList = action.payload.message.beneficiary.itemsList;
        state.itemCount = action.payload.message.beneficiary.itemCount;        ;
        state.perPage = action.payload.message.beneficiary.perPage;
        state.page = action.payload.message.beneficiary.currentPage;
        state.pageCount = action.payload.message.beneficiary.pageCount;
        state.success = action.payload.success;
    }else{
      state.message = '';
      state.success = action.payload.success;
      state.beneficiaryList = [];
      state.itemCount = 0;
      state.perPage = 10;
      state.page = 1;
      state.pageCount = 0;
    }
    state.isLoading = false;
     
    })
    .addCase(AllList_Beneficiary.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(Agent_Profile.pending, (state) => {
      state.isLoading = true;
 
    })
    .addCase(Agent_Profile.fulfilled, (state, action) => {
       
        if(action.payload.success==true){
         
          state.success = action.payload.success;
          state.message = action.payload.message;
          state.profileDetails = action.payload.message.details;
        }else{
        
          state.success = action.payload.success;
          state.message = action.payload.message;
          state.profileDetails = null;
        }

      state.isLoading = false;
     
    })
    .addCase(Agent_Profile.rejected, (state, action) => {
      state.isLoading = false;
     
    }) 

    .addCase(Agent_ProfilePosts.pending, (state) => {
      state.isLoading = true;
 
    })
    .addCase(Agent_ProfilePosts.fulfilled, (state, action) => {
        
        if(action.payload.success==true){
         
          state.success = action.payload.success;
          state.message = action.payload.message;
          state.profileDetail = action.payload.message.details;
        }else{
         
          state.success = action.payload.success;
          state.message = action.payload.message;
          state.profileDetail = null;
        }

      state.isLoading = false;
     
    })
    .addCase(Agent_ProfilePosts.rejected, (state, action) => {
      state.isLoading = false;
     
    }) 
    
    .addCase(AllListUPI.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(AllListUPI.fulfilled, (state, action) => {
     
      if(action.payload.success==true){

        state.upiList = action.payload.message.UPIUser.itemsList;
        state.itemCount = action.payload.message.UPIUser.itemCount;        ;
        state.perPage = action.payload.message.UPIUser.perPage;
        state.page = action.payload.message.UPIUser.currentPage;
        state.pageCount = action.payload.message.UPIUser.pageCount;
        state.success = action.payload.success;
    }else{
      state.message = '';
      state.success = action.payload.success;
      state.upiList = [];
      state.itemCount = 0;
      state.perPage = 10;
      state.page = 1;
      state.pageCount = 0;
    }
    state.isLoading = false;
     
    })
    .addCase(AllListUPI.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(Transfer_Payment.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    })
    .addCase(Transfer_Payment.fulfilled, (state, action) => {
    
        if(action.payload.success==true){
         
          state.success = action.payload.success;
          state.message = action.payload.message;
         
        }else{
         
          state.success = action.payload.success;
          state.message = action.payload.message.message;
         
        }

      state.isLoading = false;
      
    })
    .addCase(Transfer_Payment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    }) 
    .addCase(OTP_Payment.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    })
    .addCase(OTP_Payment.fulfilled, (state, action) => {
     
        if(action.payload.success==true){
         
          state.success = action.payload.success;
          state.message = action.payload.message;
          state.isToken = action.payload.message.message.Token;
        }else{
          
          state.success = action.payload.success;
          state.message = action.payload.message;
          state.isToken = null;
         
        }

      state.isLoading = false;
      
    })
    .addCase(OTP_Payment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    }) 

    .addCase(Check_Balance_Wallet.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    })
    .addCase(Check_Balance_Wallet.fulfilled, (state, action) => {
    
        if(action.payload.success==true){
          state.success = action.payload.success;
          state.message = action.payload.message;
        }else{
          state.success = action.payload.success;
          state.message = action.payload.message;
        }

      state.isLoading = false;
      
    })
    .addCase(Check_Balance_Wallet.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    }) 

    .addCase(Check_Balance_DebitWallet.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    })
    .addCase(Check_Balance_DebitWallet.fulfilled, (state, action) => {
    
        if(action.payload.success==true){
          state.success = action.payload.success;
          state.message = action.payload.message;
        }else{
          state.success = action.payload.success;
          state.message = action.payload.message;
        }

      state.isLoading = false;
      
    })
    .addCase(Check_Balance_DebitWallet.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    }) 
    
    .addCase(Transfer_History_Wallet.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(Transfer_History_Wallet.fulfilled, (state, action) => {
     
      if(action.payload.success==true){

        state.rechargetransationList = action.payload.message.transationList.itemsList;
        state.itemCount = action.payload.message.transationList.itemCount;        ;
        state.perPage = action.payload.message.transationList.perPage;
        state.page = action.payload.message.transationList.currentPage;
        state.pageCount = action.payload.message.transationList.pageCount;
        state.success = action.payload.success;
    }else{
      state.message = '';
      state.success = action.payload.success;
      state.rechargetransationList = [];
      state.itemCount = 0;
      state.perPage = 10;
      state.page = 1;
      state.pageCount = 0;
    }
    state.isLoading = false;
     
    })
    .addCase(Transfer_History_Wallet.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })



    .addCase(Transfer_History_Payout.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(Transfer_History_Payout.fulfilled, (state, action) => {
     
      if(action.payload.success==true){

        state.payouttransationList = action.payload.message.transationList.itemsList;
        state.itemCount = action.payload.message.transationList.itemCount;        ;
        state.perPage = action.payload.message.transationList.perPage;
        state.page = action.payload.message.transationList.currentPage;
        state.pageCount = action.payload.message.transationList.pageCount;
        state.success = action.payload.success;
    }else{
      state.message = '';
      state.success = action.payload.success;
      state.payouttransationList = [];
      state.itemCount = 0;
      state.perPage = 10;
      state.page = 1;
      state.pageCount = 0;
    }
    state.isLoading = false;
     
    })
    .addCase(Transfer_History_Payout.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })


    
    .addCase(Recharge.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(Recharge.fulfilled, (state, action) => {
     
        if(action.payload.success==true){
         
          state.success = action.payload.success;
          state.message = action.payload.message;
         
        }else{
        
          state.success = action.payload.success;
          state.message = action.payload.message.message;
         
        }

      state.isLoading = false;
      
    })
    .addCase(Recharge.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    }) 
    .addCase(RechargeOfferAgent.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(RechargeOfferAgent.fulfilled, (state, action) => {
     
        if(action.payload.success==true){
         
          state.success = action.payload.success;
          state.message = action.payload.message;
         
        }else{
        
          state.success = action.payload.success;
          state.message = action.payload.message.message;
         
        }

      state.isLoading = false;
      
    })
    .addCase(RechargeOfferAgent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    }) 

    .addCase(CheckOperatorMobile.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(CheckOperatorMobile.fulfilled, (state, action) => {
     
        if(action.payload.success==true){
         
          state.success = action.payload.success;
          state.message = action.payload.message;
         
        }else{
         
          state.success = action.payload.success;
          state.message = action.payload.message.message;
         
        }

      state.isLoading = false;
      
    })
    .addCase(CheckOperatorMobile.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    }) 
    .addCase(RechargeReOfferAgent.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(RechargeReOfferAgent.fulfilled, (state, action) => {
    
        if(action.payload.success==true){
         
          state.success = action.payload.success;
          state.message = action.payload.message;
         
        }else{
         
          state.success = action.payload.success;
          state.message = action.payload.message.message;
         
        }

      state.isLoading = false;
      
    })
    .addCase(RechargeReOfferAgent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    }) 
 

    .addCase(AllList_Agent_WithoutPage.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(AllList_Agent_WithoutPage.fulfilled, (state, action) => {
     
      if(action.payload.success==true){

        state.agentsList = action.payload.message.agents;
        state.success = action.payload.success;
    }else{
      state.message = '';
      state.success = action.payload.success;
      state.agentsList = [];
    }
    state.isLoading = false;
     
    })
    .addCase(AllList_Agent_WithoutPage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(Agent_OnBoard.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(Agent_OnBoard.fulfilled, (state, action) => {
    
      if(action.payload.success==true){

        state.responseDetails = action.payload.message;
        state.success = action.payload.success;
    }else{
      state.message = '';
      state.success = action.payload.success;
      state.responseDetails = null;
    }
    state.isLoading = false;
     
    })
    .addCase(Agent_OnBoard.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(Agent_Register_OnBoard.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(Agent_Register_OnBoard.fulfilled, (state, action) => {
    
      if(action.payload.success==true){

        state.aepsDetails = action.payload.message;
        state.success = action.payload.success;
    }else{
      state.message = '';
      state.success = action.payload.success;
      state.aepsDetails = null;
    }
    state.isLoading = false;
     
    })
    .addCase(Agent_Register_OnBoard.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(Agent_PaysPrintAuth.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(Agent_PaysPrintAuth.fulfilled, (state, action) => {
     
      if(action.payload.success==true){

        state.aepsAuthDetails = action.payload.message;
        state.success = action.payload.success;
    }else{
      state.message = '';
      state.success = action.payload.success;
      state.aepsAuthDetails = null;
    }
    state.isLoading = false;
     
    })
    .addCase(Agent_PaysPrintAuth.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(agent_Merchant_auth.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(agent_Merchant_auth.fulfilled, (state, action) => {
    
      if(action.payload.success==true){

      //  state.aepsAuthDetails = action.payload.message;
        state.success = action.payload.success;
    }else{
      state.message = '';
      state.success = action.payload.success;
     // state.aepsAuthDetails = null;
    }
    state.isLoading = false;
     
    })
    .addCase(agent_Merchant_auth.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    
    .addCase(banklist.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(banklist.fulfilled, (state, action) => {
    
      if(action.payload.success==true){

        state.bankAEPSList = action.payload.message;
        state.success = action.payload.success;
    }else{
      state.message = '';
      state.success = action.payload.success;
      state.bankAEPSList = [];
     
    }
    state.isLoading = false;
     
    })
    .addCase(banklist.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    // .addCase(Agent_OnBoard.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.error = action.payload;
    // })
    .addCase(Balance_Enquery.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(Balance_Enquery.fulfilled, (state, action) => {
      
      if(action.payload.success==true){

        state.enqueryDetails = action.payload.message;
        state.success = action.payload.success;
    }else{
      state.message = '';
      state.success = action.payload.success;
      state.enqueryDetails  = null;
    }
    state.isLoading = false;
     
    })
    .addCase(Balance_Enquery.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(agent_List_New.pending, (state) => {
      state.isLoading = true;
     
    })
    .addCase(agent_List_New.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.itemsList = action.payload.message.agents.itemsList;
        state.itemCount = action.payload.message.agents.itemCount;
        state.perPage = action.payload.message.agents.perPage;
        state.page = action.payload.message.agents.currentPage;
        state.pageCount = action.payload.message.agents.pageCount;
        state.success = true;
      } else {
        // Reset state if no data returned or unsuccessful
        state.itemsList = [];
        state.itemCount = 0;
        state.perPage = 10;
        state.page = 1;
        state.pageCount = 0;
        state.success = false;
      }
      state.isLoading = false;
    })
    
    .addCase(agent_List_New.rejected, (state, action) => {
     
      state.isLoading = false;
      state.error = action.payload;
    })

    
    .addCase(AEPS_Report_List.pending, (state) => {
      state.isLoading = true;
      
    })
    .addCase(AEPS_Report_List.fulfilled, (state, action) => {
     
      if (action.payload.success==true) {
        state.aepstransationList = action.payload.message.transationList.itemsList;
        state.itemCount = action.payload.message.transationList.itemCount;
        state.perPage = action.payload.message.transationList.perPage;
        state.page = action.payload.message.transationList.currentPage;
        state.pageCount = action.payload.message.transationList.pageCount;
        state.success = true;
      } else {
        // Reset state if no data returned or unsuccessful
        state.aepstransationList = [];
        state.itemCount = 0;
        state.perPage = 10;
        state.page = 1;
        state.pageCount = 0;
        state.success = false;
      }
      state.isLoading = false;
    })
    
    .addCase(AEPS_Report_List.rejected, (state, action) => {
     
      state.isLoading = false;
      state.error = action.payload;
    })


    .addCase(logout.pending, (state) => {
      state.isLoading = true;
     
    })
    .addCase(logout.fulfilled, (state, action) => {
     
      if (action.payload.success==true) {
        state.success = true;
      } else {
        // Reset state if no data returned or unsuccessful
        state.success = false;
      }
      state.isLoading = false;
    })
    
    .addCase(logout.rejected, (state, action) => {
     
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(WalletCreditToDebit.pending, (state) => {
      state.isLoading = true;
     
    })
    .addCase(WalletCreditToDebit.fulfilled, (state, action) => {
     
      if (action.payload.success==true) {
        state.success = true;
        state.wallet=action.payload.message;
      } else {
        // Reset state if no data returned or unsuccessful
        state.success = false;
        state.wallet=null;
      }
      state.isLoading = false;
    })
    
    .addCase(WalletCreditToDebit.rejected, (state, action) => {
    
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(ActiveService.pending, (state) => {
      state.isLoading = true;
      
    })
    .addCase(ActiveService.fulfilled, (state, action) => {
    
      if (action.payload.success==true) {
        state.success = true;
        state.activeServiceList=action.payload.message.AepsResult;
      } else {
        // Reset state if no data returned or unsuccessful
        state.success = false;
        state.activeServiceList=null;
      }
      state.isLoading = false;
    })
    
    .addCase(ActiveService.rejected, (state, action) => {
    
      state.isLoading = false;
      state.error = action.payload;
    })

    
    .addCase(AgentReports.pending, (state) => {
      state.isLoading = true;
     
    })
    .addCase(AgentReports.fulfilled, (state, action) => {
    
      if (action.payload.success==true) {
        state.debitlegerReport = action.payload.message.transationList.itemsList;
        state.itemCount = action.payload.message.transationList.itemCount;
        state.perPage = action.payload.message.transationList.perPage;
        state.page = action.payload.message.transationList.currentPage;
        state.pageCount = action.payload.message.transationList.pageCount;
        state.success = true;
      } else {
        // Reset state if no data returned or unsuccessful
        state.debitlegerReport = [];
        state.itemCount = 0;
        state.perPage = 10;
        state.page = 1;
        state.pageCount = 0;
        state.success = false;
      }
      state.isLoading = false;
    })
    
    .addCase(AgentReports.rejected, (state, action) => {
    
      state.isLoading = false;
      state.error = action.payload;
    })


    .addCase(AgentDebitReports.pending, (state) => {
      state.isLoading = true;
     
    })
    .addCase(AgentDebitReports.fulfilled, (state, action) => {
    
      if (action.payload.success==true) {
        state.debitlegerReport = action.payload.message.transationList.itemsList;
        state.itemCount = action.payload.message.transationList.itemCount;
        state.perPage = action.payload.message.transationList.perPage;
        state.page = action.payload.message.transationList.currentPage;
        state.pageCount = action.payload.message.transationList.pageCount;
        state.success = true;
      } else {
        // Reset state if no data returned or unsuccessful
        state.debitlegerReport = [];
        state.itemCount = 0;
        state.perPage = 10;
        state.page = 1;
        state.pageCount = 0;
        state.success = false;
      }
      state.isLoading = false;
    })
    
    .addCase(AgentDebitReports.rejected, (state, action) => {
     
      state.isLoading = false;
      state.error = action.payload;
    })

    
    .addCase(AgentCreditReports.pending, (state) => {
      state.isLoading = true;
    
    })
    .addCase(AgentCreditReports.fulfilled, (state, action) => {
    
      if (action.payload.success==true) {
        state.legerReport = action.payload.message.transationList.itemsList;
        state.itemCount = action.payload.message.transationList.itemCount;
        state.perPage = action.payload.message.transationList.perPage;
        state.page = action.payload.message.transationList.currentPage;
        state.pageCount = action.payload.message.transationList.pageCount;
        state.success = true;
      } else {
        // Reset state if no data returned or unsuccessful
        state.legerReport = [];
        state.itemCount = 0;
        state.perPage = 10;
        state.page = 1;
        state.pageCount = 0;
        state.success = false;
      }
      state.isLoading = false;
    })
    
    .addCase(AgentCreditReports.rejected, (state, action) => {
     
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(AgentUPILoadReports.pending, (state) => {
      state.isLoading = true;
    
    })
    .addCase(AgentUPILoadReports.fulfilled, (state, action) => {
     
      if (action.payload.success==true) {
        state.legerReport = action.payload.message.transationList.itemsList;
        state.itemCount = action.payload.message.transationList.itemCount;
        state.perPage = action.payload.message.transationList.perPage;
        state.page = action.payload.message.transationList.currentPage;
        state.pageCount = action.payload.message.transationList.pageCount;
        state.success = true;
      } else {
        // Reset state if no data returned or unsuccessful
        state.legerReport = [];
        state.itemCount = 0;
        state.perPage = 10;
        state.page = 1;
        state.pageCount = 0;
        state.success = false;
      }
      state.isLoading = false;
    })
    
    .addCase(AgentUPILoadReports.rejected, (state, action) => {
     
      state.isLoading = false;
      state.error = action.payload;
    })
    
    .addCase(AgentOnboardURL.pending, (state) => {
      state.isLoading = true;
     
    })
    .addCase(AgentOnboardURL.fulfilled, (state, action) => {
     
      if (action.payload.success==true) {
        state.callbackdata = action.payload;
      } else {
        state.callbackdata = null;
      }
      
      state.isLoading = false;
    })
    
    .addCase(AgentOnboardURL.rejected, (state, action) => {
     
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(AgentDashboardService.pending, (state) => {
      state.isLoading = true;
     
    })
    .addCase(AgentDashboardService.fulfilled, (state, action) => {
     
      if (action.payload.success==true) {
        state.activeList = action.payload.message.AepsResult;
        state.success=true;
      } else {
        state.activeList = null;
        state.success=false;
      }
      
      state.isLoading = false;
    })
    
    .addCase(AgentDashboardService.rejected, (state, action) => {
    
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(MPin_Agent.pending, (state) => {
      state.isLoading = true;
     
    })
    .addCase(MPin_Agent.fulfilled, (state, action) => {
      
      if (action.payload.success==true) {
        
        state.success=true;
      } else {
       
        state.success=false;
      }
      
      state.isLoading = false;
    })
    
    .addCase(MPin_Agent.rejected, (state, action) => {
    
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(MPinSave.pending, (state) => {
      state.isLoading = true;
     
    })
    .addCase(MPinSave.fulfilled, (state, action) => {
     
      if (action.payload.success==true) {     
        state.success=true;
      } else {    
        state.success=false;
      }
      
      state.isLoading = false;
    })
    .addCase(MPinSave.rejected, (state, action) => {
     
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(DMTQueryRemmitter.pending, (state) => {
      state.isLoading = true;
     
    })
    .addCase(DMTQueryRemmitter.fulfilled, (state, action) => {
    
      if (action.payload.success==true) {
        
        state.success=true;
      } else {
       
        state.success=false;
      }
      
      state.isLoading = false;
    }).addCase(DMTQueryRemmitter.rejected, (state, action) => {
    
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(DMTRegisterRemmtter.pending, (state) => {
      state.isLoading = true;
    
    })
    .addCase(DMTRegisterRemmtter.fulfilled, (state, action) => {
    
      if (action.payload.success==true) {
        
        state.success=true;
      } else {
       
        state.success=false;
      }
      
      state.isLoading = false;
    })
    
    .addCase(DMTRegisterRemmtter.rejected, (state, action) => {
    
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(AgentUPILoadDebit.pending, (state) => {
      state.isLoading = true;
    
    })
    .addCase(AgentUPILoadDebit.fulfilled, (state, action) => {
    
      if (action.payload.success==true) {
        
        state.success=true;
      } else {
       
        state.success=false;
      }
      
      state.isLoading = false;
    })
    
    .addCase(AgentUPILoadDebit.rejected, (state, action) => {
     
      state.isLoading = false;
      state.error = action.payload;
    })
    
    .addCase(AgentUPILoadDebitSTATUS.pending, (state) => {
      state.isLoading = true;
    
    })
    .addCase(AgentUPILoadDebitSTATUS.fulfilled, (state, action) => {
     
      if (action.payload.success==true) {
        
        state.success=true;
      } else {
       
        state.success=false;
      }
      
      state.isLoading = false;
    })
    
    .addCase(AgentUPILoadDebitSTATUS.rejected, (state, action) => {
   
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(ExpressSendOTP.pending, (state) => {
      state.isLoading = true;
     
    })
    .addCase(ExpressSendOTP.fulfilled, (state, action) => {
     
      if (action.payload.success==true) {     
        state.success=true;
      } else {
        state.success=false;
      }
      state.isLoading = false;
    })
    
    .addCase(ExpressSendOTP.rejected, (state, action) => {
     
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(ExpressRegister.pending, (state) => {
      state.isLoading = true;
     
    })
    .addCase(ExpressRegister.fulfilled, (state, action) => {
     
      if (action.payload.success==true) {     
        state.success=true;
      } else {
        state.success=false;
      }
      state.isLoading = false;
    })
    
    .addCase(ExpressRegister.rejected, (state, action) => {
    
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(ExpressRegisterFetch.pending, (state) => {
      state.isLoading = true;
     
    })
    .addCase(ExpressRegisterFetch.fulfilled, (state, action) => {
     
      if (action.payload.success==true) {     
        state.success=true;
      } else {
        state.success=false;
      }
      state.isLoading = false;
    })
    
    .addCase(ExpressRegisterFetch.rejected, (state, action) => {
     
      state.isLoading = false;
      state.error = action.payload;
    })


    
    .addCase(ExpressBenificery.pending, (state) => {
      state.isLoading = true;
     
    })
    .addCase(ExpressBenificery.fulfilled, (state, action) => {
     
      if (action.payload.success==true) {     
        state.success=true;
      } else {
        state.success=false;
      }
      state.isLoading = false;
    })
    
    .addCase(ExpressBenificery.rejected, (state, action) => {
     
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(ExpressMoneyTransfer.pending, (state) => {
      state.isLoading = true;
    
    })
    .addCase(ExpressMoneyTransfer.fulfilled, (state, action) => {
     
      if (action.payload.success==true) {     
        state.success=true;
      } else {
        state.success=false;
      }
      state.isLoading = false;
    })
    
    .addCase(ExpressMoneyTransfer.rejected, (state, action) => {
      
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(ExpressMoneyTransferConfirm.pending, (state) => {
      state.isLoading = true;
     
    })
    .addCase(ExpressMoneyTransferConfirm.fulfilled, (state, action) => {
    
      if (action.payload.success==true) {     
        state.success=true;
      } else {
        state.success=false;
      }
      state.isLoading = false;
    })
    
    .addCase(ExpressMoneyTransferConfirm.rejected, (state, action) => {
    
      state.isLoading = false;
      state.error = action.payload;
    })


    
    .addCase(ExpressUsersList.pending, (state) => {
      state.isLoading = true;
      
    })
    .addCase(ExpressUsersList.fulfilled, (state, action) => {
    
      if (action.payload.success==true) {   
        state.expressMoneyUser = action.payload.message.Users.itemsList;
        state.itemCount = action.payload.message.Users.itemCount;
        state.perPage = action.payload.message.Users.perPage;
        state.page = action.payload.message.Users.currentPage;
        state.pageCount = action.payload.message.Users.pageCount;  
        state.success=true;
      } else {
        state.expressMoneyUser = [];
        state.itemCount = 0;
        state.perPage = 10;
        state.page = 1;
        state.pageCount = 0;
        state.success = false;
        state.success=false;
      }
      state.isLoading = false;
    })
    
    .addCase(ExpressUsersList.rejected, (state, action) => {
     
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(ExpressUsersBenList.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(ExpressUsersBenList.fulfilled, (state, action) => {
    
      if(action.payload.success==true){

        state.beneficiaryList = action.payload.message.beneficiary.itemsList;
        state.itemCount = action.payload.message.beneficiary.itemCount;        ;
        state.perPage = action.payload.message.beneficiary.perPage;
        state.page = action.payload.message.beneficiary.currentPage;
        state.pageCount = action.payload.message.beneficiary.pageCount;
        state.success = action.payload.success;
    }else{
      state.message = '';
      state.success = action.payload.success;
      state.beneficiaryList = [];
      state.itemCount = 0;
      state.perPage = 10;
      state.page = 1;
      state.pageCount = 0;
    }
    state.isLoading = false;
     
    })
    .addCase(ExpressUsersBenList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })


    .addCase(Transfer_History_Express.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(Transfer_History_Express.fulfilled, (state, action) => {
     
      if(action.payload.success==true){

        state.expresstransationList = action.payload.message.transationList.itemsList;
        state.itemCount = action.payload.message.transationList.itemCount;        ;
        state.perPage = action.payload.message.transationList.perPage;
        state.page = action.payload.message.transationList.currentPage;
        state.pageCount = action.payload.message.transationList.pageCount;
        state.success = action.payload.success;
    }else{
      state.message = '';
      state.success = action.payload.success;
      state.expresstransationList = [];
      state.itemCount = 0;
      state.perPage = 10;
      state.page = 1;
      state.pageCount = 0;
    }
    state.isLoading = false;
     
    })
    .addCase(Transfer_History_Express.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    
    .addCase(Dashboardreports.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(Dashboardreports.fulfilled, (state, action) => {
     
      if(action.payload.success==true){

        state.transationList = action.payload.message.transationList;
        // state.itemCount = action.payload.message.transationList.itemCount;        ;
        // state.perPage = action.payload.message.transationList.perPage;
        // state.page = action.payload.message.transationList.currentPage;
        // state.pageCount = action.payload.message.transationList.pageCount;
        state.success = action.payload.success;
    }else{
      state.message = '';
      state.success = action.payload.success;
      state.transationList = [];
      // state.itemCount = 0;
      // state.perPage = 10;
      // state.page = 1;
      // state.pageCount = 0;
    }
    state.isLoading = false;
     
    })
    .addCase(Dashboardreports.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    
    
  },
});

export default UserSlice.reducer;
